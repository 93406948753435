import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useApplicationState } from '../../../hooks/applicationState-Context';
import WhoIsCovered from './WhoIsCovered';
import VehiclesCovered from './VehiclesCovered';
import styles from './MyCover.module.scss';
import ProductUpgradeList from './ProductUpgradeList';
import {
  GetProductUpgradeList,
  GetStaticContent,
  GetPolicyStatus,
  GetRenewalEligibility,
} from '../../../services/MyRacService';
import { filterPolicyInfoProducts, getCoverTypeFlags } from './utils';
import { ROOT, RENEWAL } from '../../../constants/routeConstants';
import coverStatus from '../../../utils/coverStatusEnum';
import trackEvent from '../../../services/AnalyticsService';
import ExpiredPolicyWarning from './ExpiredPolicyWarning';
import productUpgradeEligibility from '../../../utils/productUpgradeEligibility.enum';
import MembershipCard from './MembershipCard';
import WhatIsCovered from './WhatIsCovered';

const MyCover = ({ policy, customer }) => {
  const { appState, setPageLoading } = useApplicationState();
  const [pageModel, setPageModel] = useState();
  const [pageError, setPageError] = useState();
  const hasVehicles = policy?.policyInfo?.Vehicles?.length > 0;
  const hasUpgrades = (pageModel?.upgrades.length || 0) > 0;
  const isPolicyExpired = pageModel?.isEligibleForRenewal && [coverStatus.expired, coverStatus.lapsed].includes(pageModel?.policyStatus?.coverStatus);
  const daysToRenewal = policy?.policyInfo?.DaysToRenewal;
  const hasRightColumn = hasUpgrades || isPolicyExpired;
  const cover = getCoverTypeFlags(policy?.policyInfo);
  const navigate = useNavigate();
  const policyProducts = filterPolicyInfoProducts(policy);
  const customerId = customer?.custInfo?.Id;

  const fetchPageModelData = async () => {
    const response = await Promise.all([
      GetProductUpgradeList(customerId)
        .then((data) => data?.payload || [])
        .catch(() => ([])),
      GetStaticContent('expiredpolicyinfo'),
      GetPolicyStatus(),
      GetRenewalEligibility(customerId),
    ]).catch(() => {
      setPageLoading(false, null);
      setPageError('Error occured while fetching MyCover data.');
    });

    if (!response && response.length === 0) {
      setPageError('Error occured while fetching MyCover data.');
      setPageLoading(false, null);
      return;
    }

    const [upgradeRaw, expiredPolicyRaw, policyStatusRaw, renewalEligibilityRaw] = response;
    const newPageModel = {};

    newPageModel.upgrades = upgradeRaw.map((upgrade) => {
      // Fetching this to access long description for the modal
      if (!upgrade.productName) return null; // Trying to solve problem with productName undefined
      const policyProduct = policyProducts?.find((item) => item.productName === upgrade.productName);
      return { upgrade, policyProduct };
    }).filter(Boolean) || []; // filter(Boolean) will remove all null items after map
    newPageModel.expiredPolicyInfo = expiredPolicyRaw?.payload;
    newPageModel.policyStatus = policyStatusRaw?.payload;
    newPageModel.isEligibleForRenewal = renewalEligibilityRaw.payload.isEligibleForRenewal;

    newPageModel.extraPersonUpgradeAllowed = upgradeRaw
      .some((upgrade) => (
        upgrade.productCode === 'ExtraPerson'
        && upgrade.status === productUpgradeEligibility.UpgradeAllowed
      ));
    newPageModel.extraVehicleUpgradeAllowed = upgradeRaw
      .some((upgrade) => (
        upgrade.productCode === 'ExtraVehicle'
        && upgrade.status === productUpgradeEligibility.UpgradeAllowed
      ));

    setPageModel(newPageModel);
    setPageLoading(false, null);
  };

  const onRenew = () => {
    trackEvent('MyRAC_MyCover_ExpiredViewRenew_CTA');
    navigate({
      pathname: RENEWAL,
    });
  };

  useEffect(() => {
    if (pageModel) {
      setPageLoading(false, null);
      return;
    }
    /**
     * If user has a policy type of Pay as you go, they are not eligible for breakdown products so redirect them back to
     * the membership overview page.
     */
    if (policy?.policyInfo?.IsPayg) {
      navigate(ROOT, { replace: true });
    } else {
      fetchPageModelData();
    }
  }, [pageModel, policy]);

  if (pageError) return (<div className="d-flex justify-content-center my-4">{pageError}</div>);
  if (appState?.loading.status || policy?.policyInfo?.IsPayg) return null;
  return (
    <div data-testid="MyCoverContainer" className="d-flex flex-column flex-lg-row w-100 mb-5">
      {pageModel && (
        <div className="col-12 col-lg-7 mx-auto px-lg-4 pb-4">
          <div className="mb-3">
            <h1 className={styles.screenTitle}>My Cover</h1>
          </div>
          <div className="d-flex justify-content-center my-4">
            <MembershipCard cover={cover} />
          </div>
          <hr className={`${styles.divider} my-3 my-lg-4`} />
          <WhatIsCovered />
          {!cover.isVbm && (
            <>
              <hr className={`${styles.divider} my-3 my-lg-4`} />
              <WhoIsCovered extraPersonUpgradeAllowed={pageModel?.extraPersonUpgradeAllowed || false} />
            </>
          )}
          {hasVehicles && (cover.isHybrid || cover.isVbm) && (
            <>
              <hr className={`${styles.divider} my-3 my-lg-4`} />
              <VehiclesCovered
                items={policy?.policyInfo.Vehicles || []}
                coverType={cover.type}
                extraVehicleUpgradeAllowed={pageModel?.extraVehicleUpgradeAllowed}
              />
            </>
          )}
        </div>
      )}
      {pageModel && hasRightColumn && (
        <div className="col-12 col-lg-5 px-lg-3 mx-auto">
          {isPolicyExpired && (
            <div className="mb-3 mb-lg-4">
              <ExpiredPolicyWarning
                content={pageModel?.expiredPolicyInfo}
                action={{
                  onClick: onRenew,
                  label: pageModel?.expiredPolicyInfo?.ctaText,
                }}
                daysToRenewal={daysToRenewal}
              />
            </div>
          )}
          {hasUpgrades && <ProductUpgradeList upgrades={pageModel?.upgrades} />}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  policy: state.policy,
  customer: state.customer,
});

export default connect(mapStateToProps)(MyCover);
